@import '../../scss/mediaQueries';
@import '../../scss/colors';

.selectRecipient{
    display:flex;
    flex-direction: column;
    align-items: center;
    .app-btn{
        padding: 15px 0;
        width: 283px;
        margin: 0 auto 10px;
        display: block;
        &:last-of-type{
            margin-bottom: 11px;
        }
    }
    span.tip{
        color:#000;
        opacity: .5;
        font-size: 12px;
        text-align: center;
        display: block;
        margin-bottom: 30px;
        a{
            color:#000;
            text-decoration: underline;
        }
    }
    p.biggerTip{
        color:#000;
        font-size: 14px;
        text-align: center;
        display: block;
    }
    div.copy{
        margin-bottom: 41px;
    }
    div.navigation {
        div.row{
            div.col-xs-8{
                h2{
                    div{
                        span{
                            display: inline;
                        }
                    }
                }
            }
        }
    }
    .service-unavailable-modal{
        top: 0;right: 0;bottom: 0;left: 0;
        background: rgba(0,0,0, .5);
        position: fixed;
        display:flex;
        align-items:center;
        justify-content: center;
        z-index: 99;
        .content{
            position: relative;
            width: 35%;
            border-radius: 4px;
            background-color: #ffffff;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
            padding: 40px;
            text-align: center;
            .cancel{
                background: url('/images/cross.jpg') no-repeat center center;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 15px;
                right: 15px;
            }
            div.heading{
                margin-bottom: 24px;
            }
            p{
                text-align: center;
                margin-bottom: 6px;
                font-size: 20px;
            }
            form{
                height: auto;
                .formRow{
                    border-bottom: 0;
                }
                textarea{
                    padding: 10px;
                    width: 100%;
                    min-height: 90px;
                    border: none;
                    background-color: #fafafa;
                }
                input, textarea{
                    &::placeholder{
                        color:#a3a3a3;
                    }
                }
            }
            .app-btn{
                margin-top: 24px;
                display: inline-block;
                float: none;
                margin-bottom: 11px;
            }
            span.tip{
                color:#000;
                opacity: .5;
                font-size: 12px;
                text-align: center;
                display: block;
            }
        }
    }
}
