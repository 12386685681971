@import 'fonts';
@import 'mixins/pxToEm';
@import 'mixins/pxToRem';
@import 'mixins/fontSize';
@import 'colors';
@import 'mediaQueries';


* {
	font-family: 'loewregular', Arial;
	box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

body {

	/* border-bottom: solid 30px $hiltonBlue;
	border-top: solid 30px $hiltonBlue; */

    color:$black;

	@media ($mobile) {
		border:none;

		.logo {
			display: none;
		}
	}
}

#app{
    overflow: hidden;
}

main  {
	min-height: 100vh;
	background-color: #fff;
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@media ($mobile) {
		min-height: auto;
	}
	&.container {
		@media ($mobile) {
			padding: 20px;
		}
	}
}

strong.boldBlue {
	font-family: 'loewextrabold', Arial;
	text-transform: uppercase;
	color: $boldBlue;
}
/**
 * Form Elements
**/
.form {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	font-size: 14px;
	color: #515151;
	display: inline-block;
	overflow: hidden;
	background-color: #f5f5f5;
	border: solid 0.5px #c2c2c2;
	border-radius: 3px;

    &.autocomplete{
        margin-bottom: 4px;
        overflow: visible;
    }

	input {
		padding: 10px;
		border: none;
		border-radius: 3px;
		//background-color: transparent;
	}

	select {
		width: 100%;
		height: 100%;
		border: none;
	}

	.formRow {
		width: 100%;
		display: block;
		border-bottom: 1px solid lightgray;

		input {
			width: 100%;
			border: none;
			background-color: #fafafa;
            &[disabled] {
                color: #555;
            }
		}
	}
	.formRowFlex {
		width: 100%;
		display: flex;
		border-bottom: solid 1px lightgray;
		border-radius: 3px;

		.formColumn {
			width: 0;
		}


		& > * {
			flex: 1;
		}

		select {
			border-radius: none;
		}

        input{
            &[disabled] {
                color: #555;
            }
        }
	}

	&__composeMessage {
		margin-top: 30px;

		textarea {
			width: 100%;
			height: 300px;
			padding: 16px;
			font-size: 20px;
			border: none;
			border-radius: 3px;
			background-color: transparent;

		}
	}
}

textarea {
	outline: none;
}

.charCount {
	float: right;
	margin-right:10px;
	margin-bottom: -4px;
	font-size: 14px;
	color: #333333;
}

.red {
	color: red;
}
@media ($mobile) {

	.flip-button {
        color: $hiltonBlue;
        text-align: center;
        background-color: rgba(175, 175, 175, 0.2);
        border-color: rgba(175, 175, 175, 0.2);
        text-transform: capitalize;
        margin-left: auto;
        margin-right: auto;
        padding-left: 25px;
        padding-right: 25px;
        font-weight: 500;
	}

	.form input {
		font-size: 16px;
	}
}

textarea {
	display: inline-block;
	resize: none;
}

.back-arrow {
	color: $hiltonBlue;
	font-size: 50px;
	text-decoration: none;
	margin-top: 25px;
}

.button {
	float: right;
	background-color: $hiltonBlue !important;
	color: white;
	border-color: $hiltonBlue !important;
	font-size: 16px;
	font-weight: 200;
	/* padding-left: 25px;
	padding-right: 25px; */
	margin-top: 10px;
	&:hover {
		background-color: #154e95 !important;
		color: #fff !important;
	}
}

.nav-pills>li.active>a {
	background-color: $hiltonBlue;
}

.formColumn {
	background-color: #fafafa !important;
	border-right: solid 1px lightgray !important;
}


select.paddedSelect {
	height: 36px;
}

//GENERAL STYLES

.navigation{
    .slogan{
        span{
            display: block;
        }
        .app-btn{
            margin-top: 20px;
            float: none;
        }
    }
}

.app-btn{
    cursor: pointer;
    padding: 15px 50px;
    background: $blue;
    border-radius: 4px;
    color:$white;
    text-align: center;
    font-size: 20px;
    line-height: 1em;
    &:hover{
        color:$white;
    }
    &.s2{
        padding: 7px 12px 9px;
    }
    &.s3{
        font-size: 15px;
        font-weight: bold;
    }
    &.loading{
        color: rgba(255,255,255,.8);
        cursor: auto;
        &:hover{
            text-decoration: none;
        }
    }
}

.heading{
    margin-bottom: 41px;
    color:$blue;
    font-weight: bold;
    font-size: 20px;
}

h3{
    font-size:14px;
    margin: 0;padding: 0;
    color:$grayDark;
    text-transform: uppercase;
    font-weight: 500;
}

.flipper {
	width: 100%;
	height: 100%;
	transition: 0.6s;
	transform-style: preserve-3d;
	position: relative;
	transform: translateZ();
    .front, .back {
		width: 100%;
		height: 100%;
		backface-visibility: hidden;
        img{
            max-width: 100%;
        }
	}
}

.stationeryFlip, .preview-img{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.17);
    border: solid 1px #d9d9d9;
    width: 100%;
}

input{
    color:#282828;
    font-size:14px;
}
input::placeholder{
    color:#a3a3a3;
}

@media ($mobile) {
    .app-btn{
        padding: 12px 30px;
        font-size: 18px;
        &.s2{
            padding: 4px 8px 6px;
        }
        &.s3{
            font-size: 14px;
        }
    }
}
