@import '../../scss/mediaQueries';
@import '../../scss/colors';

@import '../../scss/mediaQueries';
@import '../../scss/colors';

.stationerySelect {
    .items {

    	.stationeryList__node {
			width: 45%;
			max-height: 422px;
            margin-bottom: 20px;

    		img {
    			width: 100%;
    		}

    		&:nth-child(odd) {
    			float: left;
    		}

    		&:nth-child(even) {
    			float: right;
			}
			
			@media ($bsBig) {
				max-height: 357px;
			}

			@media ($bsMedium) {
				max-height: 287px;
			}

    		@media ($mobile) {
				width: 100%;
				margin: 0;
				max-height: none;
    			padding-left: 5px;
    			padding-right: 5px;
    		}
    	}

        .front {
    		z-index: 2;
    		position: absolute;
    		top: 0;
    		left: 0;
    		-webkit-transform: rotateY(0deg);
    		transform: rotateY(0deg);
    	}
    }

}

.selected {
	transition: transform 200ms, box-shadow 200ms;
	box-shadow: 0px 0px 44px 0 rgba(0, 0, 0, 0.3) !important;
	transform: scale(1.1);
}
