@import '../../scss/mediaQueries';

.previewPage{
    margin-top: -80px;
    .col-md-12{
        margin-bottom: 20px;
    }

    .contact-info span{
        display: block;
    }

    .stationery_image{
        width: 100%;
    }

    .handwriting-render{
        padding-bottom: 30px;
    }

    .message-row{
        margin-top:30px;
    }
}
