@import '../../scss/mediaQueries';
@import '../../scss/colors';

.header {
	width: 100%;
	height: auto;
	// display: flex;
	// align-items: stretch;
 	// justify-content: center;
	background-color: $blue;
    padding: 20px;

    div.row{
    	> div {
    		height: 100%;
    		display: flex;
    		justify-content: center;
    		align-items: center;

    		h1{
                text-align: center;
                color:$white;
                font-size: 20px;
                font-weight: normal;
                margin: 0;
                padding: 0;
                margin-top: 20px;
            }

            .makepersonalimpression{
                max-height: 65px;
            }

            img{
                max-width: 100%;
            }

            .header_backbutton{
                display: block;
                img{
                    width: 165px;
                    max-width: 100%;
                }
            }
            .sincerely_hilton{
                display: block;
                img{
                    width: 130px;
                    max-width: 100%;
                }
            }
    	}
    }
}

@media ($tableMin) {
    .header{
        padding: 0;
        height: 100px;
        display: flex;
    	align-items: stretch;
     	justify-content: center;
        div.row{
            width: 100%;
            > div {
                h1{
                    margin-top: 0;
                }
            }
        }
    }
}
