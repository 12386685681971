// Variables, mixins and functions
@import 'scss/vars';

// Bond Styleguide files
//@import 'node_modules/style-guide/scss/base/base';

// Bases styles, resets and dependencies
@import 'scss/base';


// Utility Styles


// Typography
@import 'scss/typography';

// Page Specific Styles
@import 'pages/animationPage/animationPage';
@import 'pages/flexboxPage/flexboxPage';

@import 'pages/selectStationeryPage/selectStationeryPage';
@import 'pages/composeMessagePage/ComposeMessagePage';
@import 'pages/senderAddressPage/SenderAddressPage';
@import 'pages/recipientAddressPage/RecipientAddressPage';
@import 'pages/preview/PreviewPage';
@import 'pages/selectRecipientPage/selectRecipientPage';
@import 'pages/noteTrackerPage/noteTrackerPage';
@import 'pages/confirmationPage/ConfirmationPage';



// Global Page Components: Header, footer, navigations, etc...
@import 'components/header/header';
@import 'components/footer/footer';

// App Components
@import 'components/stationery/stationery';
@import 'components/navigation/navigation';
