@import '../../scss/mediaQueries';
@import '../../scss/colors';

.navigation{
    width: 100%;
    padding: 0px 0 30px;
    div.row{
        width: auto;
    }
    > div {
        height: 100%;
        h2{
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 16px;
            font-weight: normal;
            strong{
                color:$blue;
                font-weight: bold;
                line-height: 1.25em;
                text-transform: uppercase;
            }
            em{
                font-style: normal;
                color: #999;
            }
            div{
                > span{
                    display: inline-block;
                    margin-bottom: 20px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .backarrow{
        cursor: pointer;
        &:hover{
            opacity: .6;
        }
    }

    div.col-xs-12{
        position: relative;
        margin-top: 20px;
        padding-top: 30px;
        &:before{
            content: '';
            position: absolute;
            left: 0;right: 0;
            top: 0;
            height: 1px;
            background: #d8d8d8;
        }
    }

    .app-btn{
        display: inline-block;
        float: right;
    }
}

@media ($tableMin) {
    .navigation{
        padding: 50px 0;
        display:flex;
        align-items: center;
        flex-direction: row;
        div.row{
            width: 100%;
        }
        > div {
            h2{
                font-size: 20px;
            }
        }
    }
}
